import React,{useState, useEffect} from 'react';
import { Form, Container, Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Include Bootstrap CSS
import 'react-awesome-slider/dist/styles.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import h_shirts from '../../img/h-Shirts.jpg';
import h_accessories from '../../img/h-Accessories.jpg';
import h_bottoms from '../../img/h-Bottoms.jpg';
import h_innerwear from '../../img/h-Innerwear.jpg';
import h_jeans from '../../img/h-Jeans.jpg';
import h_shorts from '../../img/h-Shorts.jpg';
import h_t_shirts from '../../img/h-T-Shirts.jpg';
import home11 from '../../img/home11.jpg';
import home111 from '../../img/backgroud.jpg';
import home12 from '../../img/home12.jpg';
import home13 from '../../img/home13.jpg';
import home14 from '../../img/home14.jpg';
import home15 from '../../img/home15.jpg';
import home16 from '../../img/home16.jpg';
import p_logo from '../../img/Logo-GIF-Transparent.gif';
import shirt_banner from '../../img/Banner Shirts.jpg';
import tshirt_banner from '../../img/Banner T Shirts.jpg';
import hoodies_banner from '../../img/Banner Hoodies.jpg';
import jeans_banner from '../../img/Banner Jeans.jpg';
import shirts from '../../img/shirts.jpg';
import pants from '../../img/pants.jpg';
import t_shirts from '../../img/t-shirts.jpg';
import hoodies from '../../img/hoodies.jpg';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';

function HomeForm() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/ProductList');  // Navigate to the About page on click
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow next-arrow`}
        onClick={onClick}
      >
      </div>
    );
  };
  
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow prev-arrow`}
        onClick={onClick}
      >
      </div>
    );
  };  
  const banner_first = [
    shirt_banner,
    tshirt_banner,
    hoodies_banner,
    jeans_banner
  ];
  // const banner_second = [
  //   hoodies_banner,
  //   jeans_banner
  // ];
  const categories_second = [
    { name: 'Shirts', image: h_shirts },
    { name: 'Jeanse', image: h_jeans },
    { name: 'T-Shirts', image: h_t_shirts },
    { name: 'Bottoms', image: h_bottoms },
    { name: 'Shorts', image: h_shorts },
    { name: 'Innerwear', image: h_innerwear },
    { name: 'Accessories', image: h_accessories }
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Desktop
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 3, // Adjust for tablet screens
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    autoplay: false,
    autoplaySpeed: 3000, // Adjust autoplay speed as needed
    lazyLoad: 'ondemand',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Desktop
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 3, // Adjust for tablet screens
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    autoplay: false,
    autoplaySpeed: 3000, // Adjust autoplay speed as needed
    lazyLoad: 'ondemand',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  useEffect(() => {
    Swal.fire({
      title: '<strong>We’re Launching Soon!</strong>',
      html: `
        <div style="text-align: center; font-size: 16px; line-height: 1.6;">
          <p>✨ <b>Welcome to the Future of Fashion!</b> ✨</p>
          <p>Our exclusive collection is coming your way soon. Stay tuned for:</p>
          <ul style="list-style-type: none; padding: 0;">
            <li>🛍️ Trendy Apparel</li>
            <li>🧥 Unique Designs</li>
            <li>🚀 Lightning-Fast Delivery</li>
          </ul>
          <p style="margin-top: 10px;">👕 <b>See you soon in style!</b></p>
        </div>
      `,
      icon: 'info',
      confirmButtonText: 'Notify Me!',
      confirmButtonColor: '#3085d6',
      backdrop: `rgba(0,0,0,0.6)`,
      allowOutsideClick: false,
    });
  }, []);
  const settings11 = {
    autoplay: true,
    infinite: true,
    speed: 500,
    dots: false,
    lazyLoad: 'ondemand',
  }
  const settings12 = {
    autoplay: false,
    infinite: true,
    speed: 500,
    dots: false,
    lazyLoad: 'ondemand',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  const categories = [
    { name: 'Shirts', image: shirts },
    { name: 'T-Shirts', image: t_shirts },
    { name: 'Pants', image: pants },
    { name: 'Hoodies', image: hoodies },
  ];
  
  return (
    <div >
      <div className="category-menu">
        {categories.map((category, index) => (
          <div key={index} className="category-item" onClick={handleClick}>
            <img
              src={category.image}
              alt={category.name}
              className="category-image"
            />
            <span className="category-label">{category.name}</span>
          </div>
        ))}
      </div>
      <div className='home-search-box'>
          <div className=''>
            <Slider {...settings11}>
              {banner_first.map((each, index) => (
                <img src={each} alt="Image description" className="banner-image p-3" />
              ))}
            </Slider>
          </div>
      </div>
      {/* <div className='home-search-box'>
          <div className=''>
            <Slider {...settings12}>
              {banner_second.map((each, index) => (
                <img src={each} alt="Image description" className="banner-image p-3" />
              ))}
            </Slider>
          </div>
      </div> */}
      <h3 className='home_header mt-3 d-flex justify-content-center'>Urban Vibes</h3>
      <Slider {...settings2}>
        <div className="image-text-container">
          <img src={home11} alt="Image description" className="zoom-image" />
          <div className="text-content">
            <h6 className='mobile-product-name'>Men's Slim Fit Shirt - Blue</h6>
            <span className='d-flex gap-1 mobile-price-font'>
              <lable style={{color:"black"}}>₹1,020</lable>
              <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,199</lable>
              <lable style={{color:"green"}}>15% OFF</lable>
            </span>
          </div>
        </div>
        <div className="image-text-container">
          <img src={home13} alt="Image description" className="zoom-image" />
          <div className="text-content">
            <h6 className='mobile-product-name'>Men's White T-Shirt</h6>
            <span className='d-flex gap-1 mobile-price-font'>
              <lable style={{color:"black"}}>₹680</lable>
              <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹799</lable>
              <lable style={{color:"green"}}>15% OFF</lable>
            </span>
          </div>
        </div>
        <div className="image-text-container">
          <img src={home15} alt="Image description" className="zoom-image" />
          <div className="text-content">
            <h6 className='mobile-product-name'>Men's Pant</h6>
            <span className='d-flex gap-1 mobile-price-font'>
              <lable style={{color:"black"}}>₹1,360</lable>
              <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,599</lable>
              <lable style={{color:"green"}}>15% OFF</lable>
            </span>
          </div>
        </div>
        <div className="image-text-container">
          <img src={home12} alt="Image description" className="zoom-image" />
          <div className="text-content">
            <h6 className='mobile-product-name'>Men's Slim Fit Shirt - Purple</h6>
            <span className='d-flex gap-1 mobile-price-font'>
              <lable style={{color:"black"}}>₹1,020</lable>
              <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,199</lable>
              <lable style={{color:"green"}}>15% OFF</lable>
            </span>
          </div>
        </div>
        <div className="image-text-container">
          <img src={home14} alt="Image description" className="zoom-image" />
          <div className="text-content">
            <h6 className='mobile-product-name'>Men's Black T-Shirt</h6>
            <span className='d-flex gap-1 mobile-price-font'>
              <lable style={{color:"black"}}>₹680</lable>
              <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹799</lable>
              <lable style={{color:"green"}}>15% OFF</lable>
            </span>
          </div>
        </div>
        <div className="image-text-container">
          <img src={home16} alt="Image description" className="zoom-image" />
          <div className="text-content">
            <h6 className='mobile-product-name'>Men's Jeans</h6>
            <span className='d-flex gap-1 mobile-price-font'>
              <lable style={{color:"black"}}>₹1,360</lable>
              <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,599</lable>
              <lable style={{color:"green"}}>15% OFF</lable>
            </span>
          </div>
        </div>
      </Slider>
      <h3 className='home_header mt-4 d-flex justify-content-center'>Most Trending</h3>
      <Slider {...settings2}>
        <div className="image-text-container">
          <img src={home11} alt="Image description" className="zoom-image" />
          <div className="text-content">
            <h6 className='mobile-product-name'>Men's Slim Fit Shirt - Blue</h6>
            <span className='d-flex gap-1 mobile-price-font'>
              <lable style={{color:"black"}}>₹1,020</lable>
              <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,199</lable>
              <lable style={{color:"green"}}>15% OFF</lable>
            </span>
          </div>
        </div>
        <div className="image-text-container">
          <img src={home13} alt="Image description" className="zoom-image" />
          <div className="text-content">
            <h6 className='mobile-product-name'>Men's White T-Shirt</h6>
            <span className='d-flex gap-1 mobile-price-font'>
              <lable style={{color:"black"}}>₹680</lable>
              <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹799</lable>
              <lable style={{color:"green"}}>15% OFF</lable>
            </span>
          </div>
        </div>
        <div className="image-text-container">
          <img src={home15} alt="Image description" className="zoom-image" />
          <div className="text-content">
            <h6 className='mobile-product-name'>Men's Pant</h6>
            <span className='d-flex gap-1 mobile-price-font'>
              <lable style={{color:"black"}}>₹1,360</lable>
              <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,599</lable>
              <lable style={{color:"green"}}>15% OFF</lable>
            </span>
          </div>
        </div>
        <div className="image-text-container">
          <img src={home12} alt="Image description" className="zoom-image" />
          <div className="text-content">
            <h6 className='mobile-product-name'>Men's Slim Fit Shirt - Purple</h6>
            <span className='d-flex gap-1 mobile-price-font'>
              <lable style={{color:"black"}}>₹1,020</lable>
              <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,199</lable>
              <lable style={{color:"green"}}>15% OFF</lable>
            </span>
          </div>
        </div>
        <div className="image-text-container">
          <img src={home14} alt="Image description" className="zoom-image" />
          <div className="text-content">
            <h6 className='mobile-product-name'>Men's Black T-Shirt</h6>
            <span className='d-flex gap-1 mobile-price-font'>
              <lable style={{color:"black"}}>₹680</lable>
              <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹799</lable>
              <lable style={{color:"green"}}>15% OFF</lable>
            </span>
          </div>
        </div>
        <div className="image-text-container">
          <img src={home16} alt="Image description" className="zoom-image" />
          <div className="text-content">
            <h6 className='mobile-product-name'>Men's Jeans</h6>
            <span className='d-flex gap-1 mobile-price-font'>
              <lable style={{color:"black"}}>₹1,360</lable>
              <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,599</lable>
              <lable style={{color:"green"}}>15% OFF</lable>
            </span>
          </div>
        </div>
      </Slider>
      
      {/* Categories Slider */}
      <h3 className='home_header p-2 mt-4 d-flex justify-content-center'>Gentlemen’s Wear</h3>
      <Slider {...settings}>
        {categories_second.map((each, index) => (
          <div className="image-text-container" key={index} onClick={handleClick}>
            <img src={each.image} alt="Image description" className="zoom-image3" />
            {/* <div className="gray-overlay">
              <p className="overlay-text">{each.name}</p>
            </div> */}
          </div>
        ))}
      </Slider>
      <Container fluid>
        <h4 className='mt-5'>Connected With</h4>
        <div className='row justify-content-center'>
          <div className='col-auto d-flex '>
            <h5 style={{marginRight:"5px"}}><img width={100} src={p_logo}/></h5>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default HomeForm;
