import React, { useState, useEffect } from 'react';
import { Button, Card, Row, Col, Alert, Collapse } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const OrderDetails = () => {
    const { pathname } = useLocation();
    const [orders, setOrders] = useState([]);
    const [expandedOrderIds, setExpandedOrderIds] = useState([]); // Tracks expanded cards
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on route change
    }, [pathname]);
    // Mock data - replace with API calls in production
    useEffect(() => {
        setOrders([
        {
            id: '1001',
            date: '2024-10-01',
            items: [
            { name: 'T-shirt', qty: 1, price: 500 },
            { name: 'Jeans', qty: 2, price: 1500 },
            ],
            status: 'Delivered',
            estimatedDelivery: 'October 5, 2024',
            paymentMethod: 'Credit Card (**** 1234)',
            totalAmount: 2500,
            cancellable: false,
        },
        {
            id: '1002',
            date: '2024-10-10',
            items: [
            { name: 'Jacket', qty: 1, price: 2000 },
            ],
            status: 'Processing',
            estimatedDelivery: 'October 15, 2024',
            paymentMethod: 'UPI (abc@bank)',
            totalAmount: 2000,
            cancellable: true,
        },
        ]);
    }, []);

    // Toggle expand/collapse
    const toggleExpand = (orderId) => {
        setExpandedOrderIds((prev) =>
        prev.includes(orderId) ? prev.filter(id => id !== orderId) : [...prev, orderId]
        );
    };

    // Handle cancel order
    const handleCancel = (orderId) => {
        // setOrders(orders.map(order =>
        //   order.id === orderId ? { ...order, status: 'Cancelled', cancellable: false } : order
        // ));
    };

    return (
        <div className="container my-4">
        <h2 className="mb-4">Your Orders</h2>
        {orders.map(order => (
            <Card className="mb-3 shadow-sm" key={order.id}>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <div>
                <strong>Order ID:</strong> #{order.id}
                </div>
                <Button
                variant="outline-info"
                onClick={() => toggleExpand(order.id)}
                >
                {expandedOrderIds.includes(order.id) ? 'Hide Details' : 'View Details'}
                </Button>
            </Card.Header>
            <Collapse in={expandedOrderIds.includes(order.id)}>
                <Card.Body>
                <Row className="mb-3">
                    <Col><strong>Date:</strong> {order.date}</Col>
                    <Col><strong>Status:</strong> {order.status}</Col>
                </Row>
                <Row className="mb-3">
                    <Col><strong>Estimated Delivery:</strong> {order.estimatedDelivery}</Col>
                    <Col><strong>Shipping Address:</strong> 1234, Sample Address</Col>
                </Row>
                <Row className="mb-3">
                    <Col><strong>Payment Method:</strong> {order.paymentMethod}</Col>
                    <Col><strong>Total Amount:</strong> &#8377; {order.totalAmount}</Col>
                </Row>
                <h5>Items</h5>
                {order.items.map((item, index) => (
                    <Row key={index} className="border-bottom py-2">
                    <Col md={8}>{item.name}</Col>
                    <Col md={2}>Qty: {item.qty}</Col>
                    <Col md={2}>&#8377; {item.price}</Col>
                    </Row>
                ))}
                {order.cancellable && (
                    <Alert variant="warning" className="mt-3">
                    <Row>
                        <Col><strong>This order is eligible for cancellation.</strong></Col>
                        <Col className="text-end">
                        <Button variant="danger" onClick={() => handleCancel(order.id)}>
                            Cancel Order
                        </Button>
                        </Col>
                    </Row>
                    </Alert>
                )}
                </Card.Body>
            </Collapse>
            <Card.Footer className="text-muted">
                <Row>
                <Col><strong>Order Status:</strong> {order.status}</Col>
                <Col className="text-end">
                    <strong>Order Total:</strong> &#8377; {order.totalAmount}
                </Col>
                </Row>
            </Card.Footer>
            </Card>
        ))}
        </div>
    );
};

export default OrderDetails;
