import React, { useState, useEffect } from 'react';
// import ReactImageMagnify from 'react-image-magnify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-awesome-slider/dist/styles.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CartPage.css';
import shirts from '../../img/shirts.jpg';
import home11 from '../../img/home11.jpg';
import home12 from '../../img/home12.jpg';
import home13 from '../../img/home13.jpg';
import home14 from '../../img/home14.jpg';
import home15 from '../../img/home15.jpg';
import home16 from '../../img/home16.jpg';
import measurement from '../../img/measurement.jpg'
import { Modal, Table, Tab, Nav } from 'react-bootstrap';
import { TbRulerMeasure, TbTruckDelivery } from "react-icons/tb";
import { IoBagCheckOutline } from "react-icons/io5";
import { BiSolidOffer } from "react-icons/bi";
import { useNavigate, useLocation } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Loader from "../../components/Loader"; // Import the Loader component

const product = {
  images: [
    shirts,
    home13,
    home14,
    home13,
  ],
  sizes: [{size:'S',stock:'2'}, {size:'M',stock:'1'}, {size:'L',stock:'1'}, {size:'XL',stock:'0'}],
  price: 599,
  price_original:799,
  discount:"15% Off",
  suggestions: [
    {
      id: 1,
      name: 'HIGHLANDER1',
      description: 'Slim Fit Casual Shirt',
      price: 679,
      price_original:799,
      discount:"15% Off",
      images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
    },
    {
      id: 2,
      name: 'HIGHLANDER2',
      description: 'Slim Fit Casual Shirt',
      price: 679,
      price_original:799,
      discount:"15% Off",
      images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
    },
    {
      id: 3,
      name: 'HIGHLANDER3',
      description: 'Slim Fit Casual Shirt',
      price: 679,
      price_original:799,
      discount:"15% Off",
      images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
    },
    {
      id: 4,
      name: 'HIGHLANDER4',
      description: 'Slim Fit Casual Shirt',
      price: 679,
      price_original:799,
      discount:"15% Off",
      images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
    },
  ],
};
const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow next-arrow`}
      onClick={onClick}
    >
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow prev-arrow`}
      onClick={onClick}
    >
    </div>
  );
};  
const settings2 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5, // Desktop
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024, // Tablet view
      settings: {
        slidesToShow: 3, // Adjust for tablet screens
      },
    },
    {
      breakpoint: 768, // Mobile
      settings: {
        slidesToShow: 2,
      },
    },
  ],
  autoplay: false,
  autoplaySpeed: 3000, // Adjust autoplay speed as needed
  lazyLoad: 'ondemand',
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};
const productDetails = {
  "Pack of": "1",
  "Style Code": "TBH-SFERA-BL",
  "Fit": "Slim",
  "Fabric": "Pure Cotton",
  "Sleeve": "Full Sleeve",
  "Pattern": "Checkered",
  "Reversible": "No",
  "Collar": "Button Down",
  "Color": "Blue",
  "Fabric Care": "Machine wash as per tag"
};
const AddToCartPage = () => {
  const images = [shirts, home11, home12];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(product.images[0]);
  const [color, setColor] = useState('');
  const [size, setSize] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [zipCode, setZipCode] = useState('');
  const [availability, setAvailability] = useState(null);
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState(new Array(images.length).fill(false));

  const handleCloseSizeChart = () => setShowSizeChart(false);
  const handleShowSizeChart = () => setShowSizeChart(true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000); // Adjust the timeout duration as needed
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);
  const checkAvailability = () => {
    setAvailability(zipCode.startsWith('56'));
  };  
  const checkout = () => {
    navigate('/Checkout');
  }
  useEffect(() => {
    images.forEach((src, index) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setLoadedImages((prev) => {
          const newLoadedImages = [...prev];
          newLoadedImages[index] = true;
          return newLoadedImages;
        });
      };
    });
  }, [images]);
  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };
  return (
    <>
      {loading ? <Loader /> : ""}
    <div className="container mt-1">
      <div className="row">
        <div className="col-md-6">
          <div style={{ textAlign: 'center' }}>
            {/* Main image with click to open Lightbox */}
            <div>
              <img
                src={images[photoIndex]}
                alt="Main display"
                onClick={() => openLightbox(photoIndex)}
                className='main-image'
              />
            </div>

            {/* Thumbnails */}
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => setPhotoIndex(index)}
                  style={{
                    width: '80px',
                    height: '80px',
                    cursor: 'pointer',
                    border: index === photoIndex ? '2px solid blue' : 'none',
                    opacity: loadedImages[index] ? 1 : 0.5, // Dim thumbnail if not loaded
                  }}
                />
              ))}
            </div>

            {/* Lightbox for zoomable view */}
            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex((photoIndex + images.length - 1) % images.length)
                }
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
              />
            )}
          </div>
        </div>

        <div className="col-md-6">
          <h1>Product Title</h1>
          <p>Men Floral Printed Polo Collar Cotton Slim Fit T-shirt</p>
          <span className='d-flex gap-1 mobile-price-font text-larger'>
            <lable style={{color:"black",fontWeight:"bold"}}>₹{product.price}</lable>
            <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "16px",display:"flex",alignItems: "flex-end"}}>₹{product.price_original}</lable>
            <lable style={{color:"green"}}>{product.discount}</lable>
          </span>
          <p className="text-muted">(incl. of all taxes)</p>
          <ul className="list-unstyled mt-3">
            <li><BiSolidOffer size={20} className='mb-1' color='dodgerblue'/> Flat 10% Off your first purchase. Use Code: <b>FIR10</b></li>
            <li><BiSolidOffer size={20} className='mb-1' color='dodgerblue'/> Flat 20% Off on minimum purchase of 4599/- Use Code: <b>FL20</b></li>
            <li><BiSolidOffer size={20} className='mb-1' color='dodgerblue'/> Flat 15% Off on minimum purchase of 1999/- Use Code: <b>FL15</b></li>
          </ul>
          {/* Size Selection */}
          <div className="d-flex align-items-center gap-3 mt-3">
            <label className="fw-bold">Select Size</label>
            <div className="d-flex gap-2">
              {product.sizes.map((sz) => (
                <div key={sz.size} className="text-center">
                  <button
                    className={`btn btn-outline-secondary rounded-circle px-3 py-2 ${
                      size === sz.size ? 'border-primary' : ''
                    } ${ sz.stock == 0 ? 'line-through' : '' }`}
                    disabled={sz.stock == 0}
                    onClick={() => setSize(sz.size)}
                    style={{ minWidth: '45px' }}
                  >
                    {sz.size}
                  </button>
                  {sz.stock != 0 &&
                    <small className="small">{sz.stock} left</small>
                  }
                </div>
              ))}
            </div>
          </div>
          <div>
            <h6  onClick={handleShowSizeChart} className='size-chart mt-1'><TbRulerMeasure size={25}/> Size Chart</h6>
            {/* Modal for Size Chart */}
            <Modal show={showSizeChart} onHide={handleCloseSizeChart} size="lg" centered>
              <Modal.Header closeButton>
                <Modal.Title>Size Charts</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Tabs for Size Chart and How to Measure */}
                <Tab.Container defaultActiveKey="sizeChart">
                  <Nav variant="tabs" className="mb-2">
                    <Nav.Item>
                      <Nav.Link eventKey="sizeChart">Shirt Size Chart</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="howToMeasure">How to Measure</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    {/* Size Chart Tab */}
                    <Tab.Pane eventKey="sizeChart">
                      <Table bordered hover responsive>
                        <thead>
                          <tr>
                            <th>Size</th>
                            <th>Chest</th>
                            <th>Length</th>
                            <th>Shoulder</th>
                            <th>Sleeve</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>XS</td>
                            <td>36</td>
                            <td>26</td>
                            <td>17</td>
                            <td>24 1/4</td>
                          </tr>
                          <tr>
                            <td>S</td>
                            <td>38</td>
                            <td>26 1/2</td>
                            <td>17 1/2</td>
                            <td>25</td>
                          </tr>
                          <tr>
                            <td>M</td>
                            <td>40</td>
                            <td>27</td>
                            <td>18</td>
                            <td>25 1/2</td>
                          </tr>
                          <tr>
                            <td>L</td>
                            <td>42</td>
                            <td>27 1/2</td>
                            <td>18 3/4</td>
                            <td>26</td>
                          </tr>
                          <tr>
                            <td>XL</td>
                            <td>44</td>
                            <td>28</td>
                            <td>19 1/2</td>
                            <td>26 1/2</td>
                          </tr>
                          <tr>
                            <td>XXL</td>
                            <td>46</td>
                            <td>28 1/2</td>
                            <td>20 1/4</td>
                            <td>27</td>
                          </tr>
                          <tr>
                            <td>3XL</td>
                            <td>51</td>
                            <td>30</td>
                            <td>20 3/4</td>
                            <td>26</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab.Pane>

                    {/* How to Measure Tab */}
                    <Tab.Pane eventKey="howToMeasure">
                      <ul className='text-center'>
                        <img src={measurement} />
                      </ul>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Modal.Body>
            </Modal>
          </div>

          {/* Pincode Checker */}
          <div className="mt-4">
            <label className="fw-bold mb-2">Delivery Info <TbTruckDelivery /></label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter pincode"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
              <button className="btn btn-outline-secondary delivery-check" type="button" onClick={checkAvailability}>
                Check
              </button>
            </div>
            {availability !== null && (
              <p className={`mt-2 ${availability ? 'text-success' : 'text-danger'}`}>
                {availability
                  ? 'Delivery available!'
                  : 'Delivery not available in this area.'}
              </p>
            )}
          </div>
          <div className="d-flex gap-3 mt-4">
            <button className="btn btn-success btn-lg flex-grow-1">Add to Bag</button>
            <button className="btn btn-danger btn-lg" onClick={checkout}><IoBagCheckOutline size={20} className='mb-1'/> Checkout</button>
          </div>
          <hr />
          <ul className="list-unstyled mt-3">
            <li>✓ 100% Original Products</li>
            <li>✓ Pay on delivery might be available</li>
            <li>✓ Easy 14 days returns and exchanges</li>
          </ul>
          <hr />
          <div className="product-details">
            <h3>Product Details</h3>
            <ul>
              {Object.entries(productDetails).map(([key, value]) => (
                <li key={key}>
                  <strong>{key}:</strong> {value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      </div>
      <div className="suggestions m-3">
        <h3>You May Also Like</h3>
        <div className="">
          <Slider {...settings2}>
            <div className="image-text-container">
              <img src={home11} alt="Image description" className="zoom-image" />
              <div className="text-content">
                <h6 className='mobile-product-name'>Men's Slim Fit Shirt - Blue</h6>
                <span className='d-flex gap-1 mobile-price-font'>
                  <lable style={{color:"black"}}>₹1,020</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,199</lable>
                  <lable style={{color:"green"}}>15% OFF</lable>
                </span>
              </div>
            </div>
            <div className="image-text-container">
              <img src={home13} alt="Image description" className="zoom-image" />
              <div className="text-content">
                <h6 className='mobile-product-name'>Men's White T-Shirt</h6>
                <span className='d-flex gap-1 mobile-price-font'>
                  <lable style={{color:"black"}}>₹680</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹799</lable>
                  <lable style={{color:"green"}}>15% OFF</lable>
                </span>
              </div>
            </div>
            <div className="image-text-container">
              <img src={home15} alt="Image description" className="zoom-image" />
              <div className="text-content">
                <h6 className='mobile-product-name'>Men's Pant</h6>
                <span className='d-flex gap-1 mobile-price-font'>
                  <lable style={{color:"black"}}>₹1,360</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,599</lable>
                  <lable style={{color:"green"}}>15% OFF</lable>
                </span>
              </div>
            </div>
            <div className="image-text-container">
              <img src={home12} alt="Image description" className="zoom-image" />
              <div className="text-content">
                <h6 className='mobile-product-name'>Men's Slim Fit Shirt - Purple</h6>
                <span className='d-flex gap-1 mobile-price-font'>
                  <lable style={{color:"black"}}>₹1,020</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,199</lable>
                  <lable style={{color:"green"}}>15% OFF</lable>
                </span>
              </div>
            </div>
            <div className="image-text-container">
              <img src={home14} alt="Image description" className="zoom-image" />
              <div className="text-content">
                <h6 className='mobile-product-name'>Men's Black T-Shirt</h6>
                <span className='d-flex gap-1 mobile-price-font'>
                  <lable style={{color:"black"}}>₹680</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹799</lable>
                  <lable style={{color:"green"}}>15% OFF</lable>
                </span>
              </div>
            </div>
            <div className="image-text-container">
              <img src={home16} alt="Image description" className="zoom-image" />
              <div className="text-content">
                <h6 className='mobile-product-name'>Men's Jeans</h6>
                <span className='d-flex gap-1 mobile-price-font'>
                  <lable style={{color:"black"}}>₹1,360</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,599</lable>
                  <lable style={{color:"green"}}>15% OFF</lable>
                </span>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default AddToCartPage;
