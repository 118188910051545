import React, { useState, useEffect, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Include Bootstrap CSS
import 'react-awesome-slider/dist/styles.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProductList.css';
import axios from "axios";
import { SlHeart } from "react-icons/sl";
import { IoClose } from "react-icons/io5";
import { FiFilter } from 'react-icons/fi'; // For filter icon
import { Button, Offcanvas, Nav } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from "../../components/Loader"; // Import the Loader component

function ProductList() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]); // Holds products
  const [page, setPage] = useState(1); // Tracks the current page
  const [loading, setLoading] = useState(false); // Loading state
  const [loading2, setLoading2] = useState(true); // Loading state
  const [allLoaded, setAllLoaded] = useState(false); // Check if all products are loaded
  const [selectedOption, setSelectedOption] = useState('Recommended');
  const options = ['Recommended', 'Price: Low to High', 'Price: High to Low', 'New Arrivals'];
  const [selected, setSelected] = useState([]);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);
  const handleFilterChange = (e) => {
    // Call the filter function from props
  };
  // Fetch products from API
  const getProducts = async (page) => {
    // try {
    //   const response = await axios.get(
    //     `https://13.202.130.87/api/get_news_updates?page=${page}`
    //   );
    //   return response.data;
    // } catch (error) {
    //   console.error("Error fetching products:", error);
    //   throw error;
    // }
    return [
      {
        id: 1,
        name: 'HIGHLANDER',
        description: 'Slim Fit Casual Shirt',
        price: 679,
        price_original:799,
        discount:"15% Off",
        images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
      },
      {
        id: 2,
        name: 'Campus Sutra',
        description: 'Spread Collar Casual Shirt',
        price: 679,
        price_original:799,
        discount:"15% Off",
        images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
      },
      {
        id: 3,
        name: 'Campus Sutra',
        description: 'Spread Collar Casual Shirt',
        price: 679,
        price_original:799,
        discount:"15% Off",
        images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
      },
      {
        id: 4,
        name: 'Campus Sutra',
        description: 'Spread Collar Casual Shirt',
        price: 679,
        price_original:799,
        discount:"15% Off",
        images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
      },
      {
        id: 5,
        name: 'Campus Sutra',
        description: 'Spread Collar Casual Shirt',
        price: 679,
        price_original:799,
        discount:"15% Off",
        images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
      },
      // Add more products here
    ];
  };
  const handleCloseMobileFilter = () => setShowMobileFilter(false);
  const handleShowMobileFilter = () => setShowMobileFilter(true);
  const addToCart = () => {
    navigate('/CartPage');
  }
  const sliderSettings = {
    dots: true,
    autoplay: false,
    infinite: true,
    speed: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  }
  // Load products and update state
  const fetchProducts = async () => {
    if (loading || allLoaded) return; // Prevent duplicate calls
    setLoading(true);
    try {
      const newProducts = await getProducts(page);
      if (newProducts.length > 0) {
        setData((prevData) => [...prevData, ...newProducts]); // Append new products
        setPage((prevPage) => prevPage + 1); // Increment page number
      } else {
        setAllLoaded(true); // No more products to load
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Detect when user scrolls near the bottom
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
      !loading &&
      !allLoaded
    ) {
      fetchProducts(); // Load more products
    }
  }, [loading, allLoaded]);

  // Initial load of products
  useEffect(() => {
    fetchProducts(); // Load first 10 products
  }, []);

  // Attach scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleSelect = (event) => {
    setSelectedOption(event.target.value);
    console.log('Selected:', event.target.value);
  };
  function FilterSection({ title, options, selectedOptions, onSelectOption }) {
    const [isOpen, setIsOpen] = useState(true);
  
    const toggleSection = () => setIsOpen(!isOpen);
  
    return (
      <div className="filter-section">
        <div className="filter-header" onClick={toggleSection}>
          <span>{title}</span>
          <span>{isOpen ? '▼' : '▲'}</span>
        </div>
        {isOpen && (
          <div className="filter-options">
            {options.map((option, index) => (
              <label key={index} className="filter-option">
                  <div>
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(`${title}: ${option.name}`)}
                    onChange={() => onSelectOption(title, option.name)}
                  />
                  <span className={selectedOptions.includes(`${title}: ${option.name}`) ? 'selected' : ''}>
                    {option.name}
                  </span></div>
                <span className="count">({option.count})</span>
              </label>
            ))}
          </div>
        )}
      </div>
    );
  }
  const collections = [
    { name: 'Shirts', count: 343 },
    { name: 'Jeans', count: 100 },
    { name: 'T-Shirts', count: 73 },
    { name: 'Trousers', count: 61 },
    { name: 'Chinos', count: 19 },
    { name: 'Sweters', count: 18 },
    { name: 'Overshirts', count: 18 },
  ];

  const sizes = [
    { name: 'S', count: 430 },
    { name: 'M', count: 439 },
    { name: 'L', count: 445 },
    { name: 'XL', count: 448 },
    { name: 'XXL', count: 424 },
  ];  
  const fit = [
    { name: 'Slim Fit', count: 430 },
    { name: 'Regular Fit', count: 439 }
  ];
  const pattern = [
    { name: 'Plain', count: 430 }
  ];
  const color = [
    { name: 'Black', count: 430 },
    { name: 'Green', count: 430 },
    { name: 'Blue', count: 430 },
    { name: 'White', count: 430 },
    { name: 'Red', count: 430 },
    { name: 'Orange', count: 430 },
    { name: 'Yellow', count: 430 }
  ];
  const price = [
    { name: '₹299 - ₹999', count: 120 },
    { name: '₹1000 - ₹1599', count: 290 }
  ];
  const occasion = [
    { name: 'Casual wear', count: 430 },
    { name: 'Formal wear', count: 430 },
    { name: 'Festive wear', count: 430 },
    { name: 'College wear', count: 430 }
  ];
  const handleSelectOption = (category, option) => {
    const key = `${category}: ${option}`;
    setSelected((prev) =>
      prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]
    );
  };

  const clearAll = () => setSelected([]);
  function FilterSectionContainer() {
    return (
      <div className="filters-container">
      {selected.length > 0 && (
        <div className="selected-filters">
          <div className="refine-header">
            <span>YOUR PICKS</span>
            <button onClick={clearAll} className="clear-all-btn">
              Clear All
            </button>
          </div>
          <div style={{paddingBottom:"8px"}}>
            {selected.map((item, index) => {
              const [category, option] = item.split(': ');
              return (
              <div key={index} className="selected-item">
                <span>{category}: <b>{option}</b></span>
                <button className='cancel-selected-filter' onClick={() => setSelected(selected.filter((i) => i !== item))}><IoClose size={18}/></button>
              </div>
              )
            })}
          </div>
        </div>
      )}
      <FilterSection
        title="Collections"
        options={collections}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
      <FilterSection
        title="Size"
        options={sizes}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
      <FilterSection
        title="Fit"
        options={fit}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
      <FilterSection
        title="Pattern"
        options={pattern}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
      <FilterSection
        title="Color"
        options={color}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
      <FilterSection
        title="Price"
        options={price}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
      <FilterSection
        title="Occasion"
        options={occasion}
        selectedOptions={selected}
        onSelectOption={handleSelectOption}
      />
    </div>
    )
  }
  useEffect(() => {
    setTimeout(() => {
      setLoading2(false);
    }, 5000); // Adjust the timeout duration as needed
  }, []);
  return (
    <>
    {loading2 ? <Loader /> : ""}
    <div className='sort-top-menu'>
      <div className="your-pick-container d-none" onClick={handleShowMobileFilter}>
        <span>Your Picks &nbsp;</span>
        <FiFilter size={20} style={{ cursor: 'pointer' }} />
      </div>
      <Offcanvas show={showMobileFilter} onHide={handleCloseMobileFilter} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FilterSectionContainer />
        </Offcanvas.Body>
      </Offcanvas>
      <div className="sort-by-dropdown">
        <label htmlFor="sort" className="dropdown-label">Sort By:</label>
        <select id="sort" value={selectedOption} onChange={handleSelect}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
    <div className="product-page">
        <div className="filters">
          <FilterSectionContainer />
        </div>
      <div className="product-grid">
        {data.map((product) => (
            <div className="product-card">
                <Slider {...sliderSettings}>
                  {product.images.map((image, index) => (
                    <div key={index} className="image-container">
                      <img src={image} alt={`Slide ${index}`} className="product-image" />
                    </div>
                  ))}
                </Slider>
                <h6 style={{marginBottom:"0px"}}>{product.name}</h6>
                <span className='d-flex gap-1 justify-content-center mobile-price-font p-1'>
                  <lable style={{color:"black",fontWeight:"bold"}}>₹{product.price}</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹{product.price_original}</lable>
                  <lable style={{color:"green"}}>{product.discount}</lable>
                </span>
                <div className='product-button'>
                  <button className='product-button-heart'><SlHeart /></button>
                  <button onClick={addToCart}>Add to Bag</button>
                </div>
            </div>
        ))}
        {loading && <p>Loading more products...</p>}
        {allLoaded && <p>No more products to load.</p>}
      </div>
    </div>
    </>
  );
}

export default ProductList;
