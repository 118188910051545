import React, { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import './AuthModal.css';
import { MdClose } from 'react-icons/md';

const CLIENT_ID = 'YOUR_GOOGLE_CLIENT_ID'; // Replace with your Google client ID

const AuthModal = ({ show, onClose }) => {
    const [step, setStep] = useState('login'); // Tracks login/OTP flow
    const [input, setInput] = useState(''); // Stores email/phone input
    const [inputOtp, setInputOtp] = useState(''); // Stores email/phone input
  
    const handleInputChange = (e) => setInput(e.target.value);

    const handleInputChangeOtp = (e) => setInput(e.target.value);
  
    const handleGoogleSuccess = (response) => {
      console.log('Google Login Success:', response);
      onClose();
    };
  
    const handleGoogleError = (error) => {
      console.error('Google Login Failed:', error);
    };
    const verifyInfo = () =>{
        setStep('login')
    }
  
    if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container login_modal">
        <div className="modal-header">
          <h2>{step === 'login' ? 'Login / Signup' : 'Enter OTP'}</h2>
          <MdClose className="close-icon" onClick={onClose} />
        </div>

        <div className="modal-body">
          {step === 'login' ? (
            <>
              <input
                type="text"
                placeholder="Enter Email or Phone Number"
                value={input}
                onChange={handleInputChange}
                required
              />
              <button
                className="continue-btn"
                onClick={() => setStep('otp')}
                disabled={!input}
              >
                Continue
              </button>

              <div className="divider">OR</div>

              {/* <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleError}
                className="google-btn"
              /> */}
            </>
          ) : (
            <>
              <input type="text" placeholder="Enter OTP" value={inputOtp} onChange={handleInputChangeOtp} required />
              <button className="continue-btn">Verify OTP</button>
              <button className="back-btn" onClick={() => setStep('login')}>
                Back to Login
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
