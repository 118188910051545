import React, { useState, useEffect } from 'react';
import { InputAdornment, Box, Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Button, Card, CardContent, Divider, IconButton, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Menu} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import shirts from '../../img/shirts.jpg';
import home11 from '../../img/home11.jpg';
import home12 from '../../img/home12.jpg';
import home13 from '../../img/home13.jpg';
import home14 from '../../img/home14.jpg';
import home15 from '../../img/home15.jpg';
import home16 from '../../img/home16.jpg';
import './Checkout.css';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useLocation } from 'react-router-dom';
import { BiSolidOffer } from "react-icons/bi";
import Loader from "../../components/Loader"; // Import the Loader component

function Checkout() {
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState('reviewItems');
  const [showPromo, setShowPromo] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(1);
  const [quantity, setQuantity] = useState(1); // Default quantity
  const [size, setSize] = useState('M');
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const addresses = [
    { id: 1, type: 'Home', address: '123 Main St, New York, NY, 10001' },
    { id: 2, type: 'Office', address: '456 Office Park, Los Angeles, CA, 90001' },
    { id: 3, type: 'Other', address: '789 Elm St, Chicago, IL, 60601' },
  ];
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000); // Adjust the timeout duration as needed
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);
  const handleSizeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const confirm = () => {
      navigate('/OrderConfirmation');
  }
  const handleSizeSelect = (selectedSize) => {
    setSize(selectedSize);
    setAnchorEl(null);
  };
  const handleIncrease = () => {
    setQuantity((prevQuantity) => Math.min(prevQuantity + 1, 10)); // Max quantity 10
  };

  const handleDecrease = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1)); // Min quantity 1
  };
  const cartproduct = [{
      images: shirts,
      name:"HIGHLANDER1",
      description: 'Slim Fit Casual Shirt',
      sizes: 'M',
      price: 599,
      price_original:799,
      discount:"15% Off",
    },
    {
      images: shirts,
      name:"HIGHLANDER",
      description: 'Slim Fit Casual Shirt',
      sizes: 'L',
      price: 499,
      price_original:699,
      discount:"15% Off",
    }
  ]
  const [selectedItems, setSelectedItems] = useState(cartproduct.map(() => true)); // Initialize with all items selected
  // Check if at least one checkbox is checked
  const isAnyItemSelected = selectedItems.some((isSelected) => isSelected);
  const suggestions = [
      {
        id: 1,
        name: 'HIGHLANDER1',
        description: 'Slim Fit Casual Shirt',
        price: 679,
        price_original:799,
        discount:"15% Off",
        images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
      },
      {
        id: 2,
        name: 'HIGHLANDER2',
        description: 'Slim Fit Casual Shirt',
        price: 679,
        price_original:799,
        discount:"15% Off",
        images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
      },
      {
        id: 3,
        name: 'HIGHLANDER3',
        description: 'Slim Fit Casual Shirt',
        price: 679,
        price_original:799,
        discount:"15% Off",
        images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
      },
      {
        id: 4,
        name: 'HIGHLANDER4',
        description: 'Slim Fit Casual Shirt',
        price: 679,
        price_original:799,
        discount:"15% Off",
        images: ['https://dummyimage.com/210x280/ded8a6/000.png','https://dummyimage.com/210x280/949949/fff.png'],
      },
    ]
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCheckboxChange = (index) => {
    const updatedSelectedItems = [...selectedItems];
    updatedSelectedItems[index] = !updatedSelectedItems[index];
    setSelectedItems(updatedSelectedItems);
  };
 
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow next-arrow`}
        onClick={onClick}
      >
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow prev-arrow`}
        onClick={onClick}
      >
      </div>
    );
  };  
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Desktop
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 3, // Adjust for tablet screens
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    autoplay: false,
    autoplaySpeed: 3000, // Adjust autoplay speed as needed
    lazyLoad: 'ondemand',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className='p-4'>
      {loading ? <Loader /> : ""}
      <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} gap={2}>
        
        {/* Left side - Steps */}
        <Box flex={1}>
          {/* Step 1: Review items */}
          <Accordion expanded={expanded === 'reviewItems'} onChange={handleChange('reviewItems')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="reviewItems-content" id="reviewItems-header">
              <Typography variant="h6">Review items</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {/* Address List */}
                {cartproduct.map((item, index) => (
                  <Box
                    key={item.id}
                    className="checkout-product-mobile"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    border={1}
                    borderColor="grey.300"
                    borderRadius={2}
                    mb={2}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedItems[index]}
                          onChange={() => handleCheckboxChange(index)}
                          color="primary"
                        />
                      }
                      label={
                        <Box display="flex" alignItems="center">
                        <Box>
                          <Typography variant="subtitle1" fontWeight="bold">
                          <img
                              srcSet={`${item.images}?w=50&h=50&fit=crop&auto=format&dpr=2 2x`}
                              src={`${item.images}?w=50&h=50&fit=crop&auto=format`}
                              className='checkout-cart-image'
                              alt={item.name}
                              loading="lazy"
                            />
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {item.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {item.description}
                          </Typography>
                          <Typography variant="body2" className='' color="textSecondary" display="flex" alignItems="center" gap={1}>
                            <lable style={{color:"black",fontWeight:"bold"}}>₹{item.price}</lable>
                            <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹{item.price_original}</lable>
                            <lable style={{color:"green"}}>{item.discount}</lable>
                          </Typography>
                        </Box>
                      </Box>
                      }
                    />
                      <Box className="checkout-product-qty" gap={{ xs: 4, sm: 30}} sx={{ textAlign: 'center', padding: '10px' }}>
                        {/* Size Dropdown */}
                        <Button
                          onClick={handleSizeClick}
                          sx={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: '#000',
                            textTransform: 'none',
                            padding: 0,
                            '&:hover': { backgroundColor: 'transparent' },
                          }}
                        >
                          <span style={{fontWeight:"normal"}}>Size:</span> {size}
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          {['S', 'M', 'L', 'XL'].map((option) => (
                            <MenuItem
                              key={option}
                              onClick={() => handleSizeSelect(option)}
                              sx={{ fontSize: '16px' }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>

                        {/* Quantity Selector */}
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '10px',
                            border: '1px solid #000',
                            borderRadius: '4px',
                            overflow: 'hidden',
                            width: 'fit-content',
                            backgroundColor: '#fff',
                          }}
                        >
                          <IconButton
                            onClick={handleDecrease}
                            sx={{
                              borderRadius: 0,
                              padding: '4px 8px', // Smaller padding
                              backgroundColor: '#000',
                              color: '#fff',
                              '&:hover': { backgroundColor: '#333' },
                            }}
                          >
                            <RemoveIcon fontSize="small" />
                          </IconButton>
                          <Typography
                            variant="body2" // Smaller font size
                            sx={{
                              width: '32px', // Reduced width
                              textAlign: 'center',
                              fontWeight: 'bold',
                              color: '#000',
                            }}
                          >
                            {quantity}
                          </Typography>
                          <IconButton
                            onClick={handleIncrease}
                            sx={{
                              borderRadius: 0,
                              padding: '4px 8px',
                              backgroundColor: '#000',
                              color: '#fff',
                              '&:hover': { backgroundColor: '#333' },
                            }}
                          >
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                      <Typography variant="body2" className='checkout-product-qty' color="textSecondary">
                        <Button variant="outlined">Move to Whishlist</Button>
                      </Typography>
                  </Box>
                ))}

              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Step 2: Shipping Address */}
          <Accordion expanded={expanded === 'shippingAddress'} onChange={handleChange('shippingAddress')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="shippingAddress-content" id="shippingAddress-header">
              <Typography variant="h6">Shipping Address</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {/* Address List */}
                {addresses.map((item) => (
                  <Box
                    key={item.id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    border={1}
                    borderColor="grey.300"
                    borderRadius={2}
                    mb={2}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedAddress === item.id}
                          onChange={() => setSelectedAddress(item.id)}
                          color="primary"
                        />
                      }
                      label={
                        <Box>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {item.type}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {item.address}
                          </Typography>
                        </Box>
                      }
                    />
                    <IconButton color="primary" onClick={handleOpen}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                ))}

                {/* Add New Address Button */}
                <Button variant="outlined" color="primary" fullWidth onClick={handleOpen} size="large">
                  Add New Address
                </Button>

                {/* New Address Dialog (Popup) */}
                <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                  <DialogTitle>Add New Address</DialogTitle>
                  <DialogContent>
                    <Box component="form" mt={1} noValidate autoComplete="off">
                      <Box display="flex" gap={2} mb={2}>
                        <TextField label="First Name" variant="outlined" fullWidth required />
                        <TextField label="Last Name" variant="outlined" fullWidth required />
                      </Box>
                      <TextField label="Phone Number" variant="outlined" fullWidth required sx={{ mb: 2 }}/>
                      <Box display="flex" gap={2} mb={2}>
                      <TextField label="Zip Code" variant="outlined" fullWidth required />
                      </Box>
                      <TextField label="Street Address" variant="outlined" fullWidth required sx={{ mb: 2 }} />
                      <Box display="flex" gap={2} mb={2}>
                        <TextField
                          label="State"
                          variant="outlined"
                          select
                          SelectProps={{ native: true }}
                          fullWidth
                          value="NY"
                          required
                        >
                          <option value="">Select</option>
                          <option value="NY">NY</option>
                          <option value="CA">CA</option>
                          {/* Add more states as needed */}
                        </TextField>
                        <TextField label="City" variant="outlined" fullWidth required />
                      </Box>
                      <Box display="flex" gap={2} mb={2}>
                        <TextField label="Type Of Address" variant="outlined" fullWidth />
                      </Box>
                      <Box display="flex" gap={2} mb={2}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Make this as my default address" />
                      </Box>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="secondary">Cancel</Button>
                    <Button onClick={handleClose} variant="contained" color="primary">
                      Save Address
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Step 3: Offers */}
          <Accordion expanded={expanded === 'Offers'} onChange={handleChange('Offers')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="Offers-content" id="Offers-header">
              <Typography variant="h6">Offers</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <ul className="list-unstyled mt-3">
                  <li><BiSolidOffer size={20} className='mb-1' color='dodgerblue'/> Flat 10% Off your first purchase. Use Code: <b>FIR10</b></li>
                  <li><BiSolidOffer size={20} className='mb-1' color='dodgerblue'/> Flat 20% Off on minimum purchase of 4599/- Use Code: <b>FL20</b></li>
                  <li><BiSolidOffer size={20} className='mb-1' color='dodgerblue'/> Flat 15% Off on minimum purchase of 1999/- Use Code: <b>FL15</b></li>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>
          
          {/* Step 4: Terms and Conditions */}
          <Accordion expanded={expanded === 'termsConditions'} onChange={handleChange('termsConditions')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="termsConditions-content" id="termsConditions-header">
              <Typography variant="h6">Terms and Conditions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Accordion>
                  <Box sx={{ marginBottom: '20px' }} padding={2}>
                    <Typography variant="h6" gutterBottom>General Information</Typography>
                    <Typography variant="body1">
                      Welcome to ThirtySeven. By accessing or using our website, you agree to comply with and be bound by the
                      following terms and conditions. Please read these terms carefully before using our website.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Product Information</Typography>
                    <Typography variant="body1">
                      We strive to display our products as accurately as possible. However, colors, sizes, and product images
                      may vary due to screen display differences.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Pricing and Payments</Typography>
                    <Typography variant="body1">
                      Prices are in Indian Rupees (INR) and include applicable taxes. Orders above Rs.1000 qualify for free
                      shipping. Payment methods include credit/debit cards, UPI, and other secure options provided on checkout.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Shipping Policy</Typography>
                    <Typography variant="body1">
                      We offer free shipping on orders above Rs.1000. Orders are processed within 2-3 business days and will be
                      delivered within 5-7 days depending on your location.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Limitation of Liability</Typography>
                    <Typography variant="body1">
                      ThirtySeven is not liable for any indirect or consequential damages arising from the use of our website or
                      services.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Changes to Terms</Typography>
                    <Typography variant="body1">
                      We reserve the right to update these terms at any time without prior notice. Changes will be effective
                      immediately upon posting to the website.
                    </Typography>
                  </Box>
                </Accordion>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Step 5: Cancellation and Return Policy */}
          <Accordion expanded={expanded === 'CancellationReturn'} onChange={handleChange('CancellationReturn')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="CancellationReturn-content" id="CancellationReturn-header">
              <Typography variant="h6">Cancellation and Return Policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
              <Accordion>
                  <Box sx={{ marginBottom: '20px' }} padding={2}>
                    <Typography variant="h6" gutterBottom>Return Eligibility</Typography>
                    <Typography variant="body1">
                      You may return items within 7 days of delivery. To be eligible for a return, items must be unworn, unwashed, with all tags attached, and in their original packaging
                    </Typography>
                    <Typography variant="h6" gutterBottom>Return Process</Typography>
                    <Typography variant="body1">
                      To initiate a return, please contact our customer support team with your order details. We will guide you through the process, and you may be required to ship the items back to us.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Refund Policy</Typography>
                    <Typography variant="body1">
                      Once we receive and inspect your return, we will process your refund within 5-7 business days. Refunds will be issued to the original payment method.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Exchange Policy</Typography>
                    <Typography variant="body1">
                    Exchanges are available within 7 days for items that meet the return eligibility criteria. Contact our customer support to initiate an exchange.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Order Cancellation</Typography>
                    <Typography variant="body1">
                      Orders can be canceled within 24 hours of placement. Once an order is processed, it cannot be canceled.
                    </Typography>
                    <Typography variant="h6" gutterBottom>Non-Returnable Items</Typography>
                    <Typography variant="body1">
                    Some items may not be eligible for return due to hygiene reasons (e.g., innerwear). Please check the product details for return eligibility.
                    </Typography>
                  </Box>
                </Accordion>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* Right side - Order Summary */}
        <Box flex={{ xs: 1, lg: 0.4 }}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>Order Summary</Typography>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography>Subtotal (2 items)</Typography>
                <Typography>₹2000</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography>Savings</Typography>
                <Typography color="green">-₹200</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography>Shipping</Typography>
                <Typography color="error"><label className='line-through' style={{ fontSize: '13px',color:"#b9b9b9" }}>₹100 </label>Free</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography>Taxes(+12%)</Typography>
                <Typography>₹120</Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="h6">Estimated Total</Typography>
                <Typography variant="h6">₹1920</Typography>
              </Box>

              {/* Promo Code */}
              <Button color="primary" onClick={() => setShowPromo(!showPromo)} sx={{ mb: 1 }}>
                Have a promo code?
              </Button>
                {showPromo && (
                  <TextField
                  placeholder="Enter promo code"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button variant="contained" color="secondary" size="large">
                          Apply
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 1 }}
                />
                )}
              <Button variant="contained" color="primary" size="large" onClick={confirm} fullWidth disabled={!isAnyItemSelected}>Buy Now</Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
      
      <div className="suggestions m-3 pt-5">
        <h3>Add More</h3>
        <div className="">
          <Slider {...settings2}>
            <div className="image-text-container">
              <img src={home11} alt="Image description" className="zoom-image" />
              <div className="text-content">
                <h6 className='mobile-product-name'>Men's Slim Fit Shirt - Blue</h6>
                <span className='d-flex gap-1 mobile-price-font'>
                  <lable style={{color:"black"}}>₹1,020</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,199</lable>
                  <lable style={{color:"green"}}>15% OFF</lable>
                </span>
              </div>
            </div>
            <div className="image-text-container">
              <img src={home13} alt="Image description" className="zoom-image" />
              <div className="text-content">
                <h6 className='mobile-product-name'>Men's White T-Shirt</h6>
                <span className='d-flex gap-1 mobile-price-font'>
                  <lable style={{color:"black"}}>₹680</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹799</lable>
                  <lable style={{color:"green"}}>15% OFF</lable>
                </span>
              </div>
            </div>
            <div className="image-text-container">
              <img src={home15} alt="Image description" className="zoom-image" />
              <div className="text-content">
                <h6 className='mobile-product-name'>Men's Pant</h6>
                <span className='d-flex gap-1 mobile-price-font'>
                  <lable style={{color:"black"}}>₹1,360</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,599</lable>
                  <lable style={{color:"green"}}>15% OFF</lable>
                </span>
              </div>
            </div>
            <div className="image-text-container">
              <img src={home12} alt="Image description" className="zoom-image" />
              <div className="text-content">
                <h6 className='mobile-product-name'>Men's Slim Fit Shirt - Purple</h6>
                <span className='d-flex gap-1 mobile-price-font'>
                  <lable style={{color:"black"}}>₹1,020</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,199</lable>
                  <lable style={{color:"green"}}>15% OFF</lable>
                </span>
              </div>
            </div>
            <div className="image-text-container">
              <img src={home14} alt="Image description" className="zoom-image" />
              <div className="text-content">
                <h6 className='mobile-product-name'>Men's Black T-Shirt</h6>
                <span className='d-flex gap-1 mobile-price-font'>
                  <lable style={{color:"black"}}>₹680</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹799</lable>
                  <lable style={{color:"green"}}>15% OFF</lable>
                </span>
              </div>
            </div>
            <div className="image-text-container">
              <img src={home16} alt="Image description" className="zoom-image" />
              <div className="text-content">
                <h6 className='mobile-product-name'>Men's Jeans</h6>
                <span className='d-flex gap-1 mobile-price-font'>
                  <lable style={{color:"black"}}>₹1,360</lable>
                  <lable className="mobile-price-font2" style={{color:"#999",textDecorationLine:"line-through", fontSize: "14px",display:"flex",alignItems: "flex-end"}}>₹1,599</lable>
                  <lable style={{color:"green"}}>15% OFF</lable>
                </span>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
