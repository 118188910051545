import React, { useState } from 'react';
import { Navbar, Nav, Offcanvas, Container, Form, InputGroup } from 'react-bootstrap'; 
import { FaRegUserCircle, FaFacebookF, FaInstagram, FaYoutube, FaChevronRight } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import './Sidebar.css'; 
import logo from '../img/Logo-GIF-Transparent.gif';
import logo2 from '../img/mail-phone-icon.png';
import { SlUser, SlHandbag, SlHeart, SlHome } from "react-icons/sl";
import AuthModal from './AuthModal';
import { useNavigate, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const navigate = useNavigate();
    const [showSidebar, setShowSidebar] = useState(false); // Manage sidebar visibility
  
    const toggleSidebar = () => setShowSidebar(!showSidebar);
    const [activeMenu, setActiveMenu] = useState(null); // Track which menu is open
    const handleMenuClick = (menu) => {
        setActiveMenu(activeMenu === menu ? null : menu); // Toggle the menu on click
      };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation();
    const isActive = (path) => location.pathname === path;

    const openModal = () => {
      setIsModalOpen(true);
      setShowSidebar(false);
    }
    const selectedSideMenu = () => {
      setShowSidebar(false);
      navigate('/ProductList');
    }
    const handleProfilePage = () => {
      navigate('/ProfilePage');
    }
    const handleWishlist = () => {
      navigate('/Wishlist');
    }
    const handleCart = () => {
      navigate('/Checkout');
    }
    const handleContactUs = () => {
      navigate('/ContactUs');
      setShowSidebar(false);
    }
    const closeModal = () => setIsModalOpen(false);
    const [bagCount, setBagCount] = useState(3); 
    const [wishlistCount, setWishlistCount] = useState(5);
  return (
    <>
      {/* Navbar with Toggle Button */}
      <Navbar bg="light" variant="light" expand={false} className="mb-3">
        <Container fluid className="d-flex align-items-center justify-content-between">
          {/* Force Toggle Button to show on all screen sizes */}
          <div className="text-center flex-grow-1">
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            onClick={toggleSidebar}
            className="toggle-button"
          />
          </div>
          {/* Center: Brand Name */}
          <div className="text-center flex-grow-1 center-icon-mobile">
            <Navbar.Brand href="/"><img src={logo} alt="Logo" className="logo-img"/></Navbar.Brand>
          </div>
          {/* Right: Search & Icons */}
          <div className="d-flex gap-4 align-items-center">
            
            {/* Show other icons only on desktop */}
            <div className="d-none d-md-flex gap-4">
                <div className="search-container">
                    <InputGroup className="search-input-mobile">
                        <Form.Control 
                        type="text" 
                        placeholder="Search..."
                        />
                    </InputGroup>
                </div>
                <div className="icon-with-label" onClick={handleCart}>
                    <SlHandbag className="nav-icon" />
                    <span className="icon-label">Bag</span>
                    {bagCount > 0 && <span className="count-badge">{bagCount}</span>}
                </div>
                <div className="icon-with-label" onClick={handleWishlist}>
                    <SlHeart className="nav-icon" />
                    <span className="icon-label">Wishlist</span>
                    {wishlistCount > 0 && <span className="count-badge">{wishlistCount}</span>}
                </div>
                <div className="icon-with-label" onClick={handleProfilePage}>
                    <SlUser className="nav-icon" />
                    <span className="icon-label">Profile</span>
                </div>
            </div>
          </div>
        </Container>
        {/* Search Icon & Input */}
        <div className="search-container-mobile">
            <InputGroup>
                <Form.Control 
                type="text" 
                placeholder="Search..."
                />
            </InputGroup>
        </div>
      </Navbar>

      {/* Offcanvas Sidebar */}
      <Offcanvas 
        show={showSidebar} 
        onHide={() => setShowSidebar(false)} 
        backdrop 
        className="sidebar-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='icon-title2'><FaRegUserCircle size={30}/><span className="icon-label2">Hello!</span></Offcanvas.Title>
          {/* Button Below the Title */}
        </Offcanvas.Header>
        <div className="login-signup-btn-container">
            <button className="login-signup-btn" onClick={openModal}>Login / Sign up</button>
        </div>
        <Offcanvas.Body>
          <Nav className="flex-column">
          <Nav.Item onClick={() => handleMenuClick('sale')} className="nav-item">
            <Nav.Link href="#">
              Sale
              <FaChevronRight className="arrow-icon" />
            </Nav.Link>
            {/* Sub-menu shown conditionally */}
            {activeMenu === 'sale' && (
              <div className="submenu">
                <Nav.Link href="#" onClick={selectedSideMenu}>Men</Nav.Link>
                <Nav.Link href="#" onClick={selectedSideMenu}>Women</Nav.Link>
                <Nav.Link href="#" onClick={selectedSideMenu}>Kids</Nav.Link>
              </div>
            )}
          </Nav.Item>
            <hr className='hr'/>
            <Nav.Item onClick={() => handleMenuClick('men')} className="nav-item">
            <Nav.Link href="#">
              Men
              <FaChevronRight className="arrow-icon" />
            </Nav.Link>
            {activeMenu === 'men' && (
              <div className="submenu">
                <Nav.Link href="#" onClick={selectedSideMenu}>Shirts</Nav.Link>
                <Nav.Link href="#" onClick={selectedSideMenu}>T-Shirts</Nav.Link>
                <Nav.Link href="#" onClick={selectedSideMenu}>Jeans</Nav.Link>
              </div>
            )}
            <hr className="hr" />
          </Nav.Item>
            <Nav.Link href="#" onClick={selectedSideMenu}>New Arrivals</Nav.Link>
            <hr className='hr'/>
            <Nav.Link href="#" onClick={selectedSideMenu}>Featured</Nav.Link>
            <hr className='hr'/>
            <Nav.Link href="#"  onClick={handleContactUs}><img src={logo2} alt="Logo" width={35}/><span className='contact-text'>Contact Us</span></Nav.Link>
          </Nav>
          {/* Custom Footer at the Bottom */}
            <div className="offcanvas-footer">
                <p>Follow us:</p>
                <div className="social-icons">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                    <FaFacebookF className="social-icon" />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="social-icon" />
                </a>
                <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                    <FaYoutube className="social-icon" />
                </a>
                <a href="https://x.com" target="_blank" rel="noopener noreferrer">
                    <FaXTwitter className="social-icon" />
                </a>
                </div>
            </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Bottom Mobile Menu */}
      <div className="bottom-menu d-md-none">
        <div className={`menu-item ${isActive('/') ? 'active-bottom' : ''}`} onClick={() => navigate('/')}>
          <SlHome className="nav-icon" />
          <span className="icon-label">Home</span>
        </div>
        <div className={`menu-item ${isActive('/Checkout') ? 'active-bottom' : ''}`} onClick={handleCart}>
          <SlHandbag className="nav-icon" />
          <span className="icon-label">Bag</span>
          {bagCount > 0 && <span className="count-badge">{bagCount}</span>}
        </div>
        <div className={`menu-item ${isActive('/Wishlist') ? 'active-bottom' : ''}`} onClick={handleWishlist}>
          <SlHeart className="nav-icon" />
          <span className="icon-label">Wishlist</span>
          {wishlistCount > 0 && <span className="count-badge">{wishlistCount}</span>}
        </div>
        <div className={`menu-item ${isActive('/ProfilePage') ? 'active-bottom' : ''}`} onClick={handleProfilePage}>
          <SlUser className="nav-icon" />
          <span className="icon-label">Profile</span>
        </div>
      </div>
      <AuthModal show={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default Sidebar;
