// Loader.js
import React from "react";
import loaderGif from "../img/loader.gif"; // Update the path to your GIF file

const Loader = () => {
  return (
    <div style={loaderContainerStyle}>
      <img src={loaderGif} alt="Loading..." style={loaderStyle} />
    </div>
  );
};

const loaderContainerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(211, 211, 211, 0.8)", // Light gray with transparency
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999,
};

const loaderStyle = {
    width: "100px", // Customize the size as needed
    height: "100px",
};

export default Loader;
