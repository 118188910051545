// ProfilePage.js
import React, { useState, useEffect} from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Tab, Tabs, Box, Typography, Button, Grid, Card, CardHeader, IconButton, Collapse, CardContent, Alert, CardActions, Divider, TextField, DialogActions, Checkbox, FormControlLabel, DialogContent, Dialog, DialogTitle, Avatar } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from '@mui/lab';
import { AccountCircle, History, LocationOn, Loyalty, GroupAdd, Logout, Cancel, ExpandMore, CheckCircle, LocalShipping, Edit, Redeem, Star, PersonAdd, Share, FileCopy } from '@mui/icons-material';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProfilePage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Invoice from '../../components/Invoice';
import Loader from "../../components/Loader"; // Import the Loader component

function ProfilePage() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);

  // Handlers for switching tabs
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleLogout = () => {
    navigate('/');
  }
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000); // Adjust the timeout duration as needed
  }, []);
  return (
    <Container fluid className="p-3" style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
      {loading ? <Loader /> : ""}
      <Row>
        <Col lg={3} md={4} className="mb-4">
          {/* Sidebar Navigation */}
          <div className="profile-sidebar p-3 bg-white rounded shadow">
            <h5 className="text-center mb-4">My Profile</h5>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Button
                  startIcon={<AccountCircle />}
                  onClick={() => setSelectedTab(0)}
                  fullWidth
                  color={selectedTab === 0 ? 'primary' : 'inherit'}
                >
                  Account Details
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  startIcon={<History />}
                  onClick={() => setSelectedTab(1)}
                  fullWidth
                  color={selectedTab === 1 ? 'primary' : 'inherit'}
                >
                  Order History
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  startIcon={<LocationOn />}
                  onClick={() => setSelectedTab(2)}
                  fullWidth
                  color={selectedTab === 2 ? 'primary' : 'inherit'}
                >
                  Manage Addresses
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  startIcon={<Loyalty />}
                  onClick={() => setSelectedTab(3)}
                  fullWidth
                  color={selectedTab === 3 ? 'primary' : 'inherit'}
                >
                  Reward Points
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  startIcon={<GroupAdd />}
                  onClick={() => setSelectedTab(4)}
                  fullWidth
                  color={selectedTab === 4 ? 'primary' : 'inherit'}
                >
                  Refer a friend
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  startIcon={<Logout />}
                  onClick={handleLogout}
                  fullWidth
                  color='error'
                >
                  Logout
                </Button>
              </Nav.Item>
            </Nav>
          </div>
        </Col>

        <Col lg={9} md={8}>
          <Box className="bg-white p-4 rounded shadow">
            <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary">
              <Tab label="Account Details" />
              <Tab label="Order History" />
              <Tab label="Manage Addresses" />
              <Tab label="Reward Points" />
              <Tab label="Refer a friend" />
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
              <AccountDetails />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <OrderHistory />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <ManageAddresses />
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
              <Reward />
            </TabPanel>
            <TabPanel value={selectedTab} index={4}>
              <Refer />
            </TabPanel>
          </Box>
        </Col>
      </Row>
    </Container>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Account Details Component
function AccountDetails() {
  const [formData, setFormData] = useState({
    name: 'John Doe',
    phone: '1234567890',
    email: 'johndoe@example.com',
    dob: '1990-01-01',
    address: '123 Main St, City, Country',
    occupation: 'Software Engineer',
  });

  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEdit = () => {
    console.log('abcd')
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted:', formData);
    setIsEditing(false);
  };

  return (
    <Container maxWidth="sm" >
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          {/* Name */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              disabled={!isEditing}
              required
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              disabled={!isEditing}
              required
              type="tel"
              inputProps={{ maxLength: 10 }}
              helperText="Enter 10-digit phone number"
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              disabled={!isEditing}
              required
              type="email"
            />
          </Grid>

          {/* Date of Birth */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Date of Birth"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              disabled={!isEditing}
              required
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        {/* Action Buttons */}
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          {isEditing ? (
            <>
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </>
          ) : (""
          )}
        </Box>
      </Box>
      {!isEditing ? (
          <Button
              type='button'
              variant="contained"
              color="primary"
              onClick={handleEdit}
            >
              Edit
            </Button>
      ):("")}
    </Container>
  );
}

// Order History Component
function OrderHistory() {
    
  const [orders, setOrders] = useState([]);
  const [expandedOrderIds, setExpandedOrderIds] = useState([]); // Tracks expanded cards

  const toggleExpand = (orderId) => {
    setExpandedOrderIds((prev) =>
      prev.includes(orderId) ? prev.filter((id) => id !== orderId) : [...prev, orderId]
    );
  };
  const invoice_orders = [
    {
      id: '12345',
      date: '2023-11-10',
      customerName: 'John Doe',
      customerAddress: '456 Avenue, City, State, India',
      customerEmail: 'john.doe@example.com',
      items: [
        { name: 'T-Shirt', quantity: 2, price: 499 },
        { name: 'Jeans', quantity: 1, price: 1299 },
      ],
      total: 2297,
    },
  ];
  
  useEffect(() => {
    setOrders([
        {
            id: '1001',
            date: '2024-10-01',
            items: [
            { name: 'T-shirt', qty: 1, price: 500 },
            { name: 'Jeans', qty: 2, price: 1500 },
            ],
            status: 'Delivered',
            estimatedDelivery: 'October 5, 2024',
            paymentMethod: 'Credit Card (**** 1234)',
            totalAmount: 2500,
            cancellable: false,
        },
        {
            id: '1002',
            date: '2024-10-10',
            items: [
            { name: 'Jacket', qty: 1, price: 2000 },
            ],
            status: 'Processing',
            estimatedDelivery: 'October 15, 2024',
            paymentMethod: 'UPI (abc@bank)',
            totalAmount: 2000,
            cancellable: true,
        },
        ]);
    }, []);
    const tracking = ([
        {
            status: 'Ordered',
            date: '2024-10-01',
            time:"12:40 AM"
        },
        {
            status: 'Shipped',
            date: '2024-10-02',
            time:"11:00 AM"
        },
        {
            status: 'Delivered',
            date: '2024-10-04',
            time:"10:00 AM"
        },
    ])
  return (
    <Box>
      {orders.map((order,index) => (
        <Card key={order.id} sx={{ marginBottom: 2, boxShadow: 1 }}>
          <CardHeader
            title={`Order ID: #${order.id}`}
            action={
              <IconButton onClick={() => toggleExpand(order.id)} color="info">
                <ExpandMore
                  sx={{
                    transform: expandedOrderIds.includes(order.id) ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease'
                  }}
                />
              </IconButton>
            }
          />
          <Collapse in={expandedOrderIds.includes(order.id)} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Date:</strong> {order.date}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Status:</strong> {order.status}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Estimated Delivery:</strong> {order.estimatedDelivery}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Shipping Address:</strong> 1234, Sample Address</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Payment Method:</strong> {order.paymentMethod}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1"><strong>Total Amount:</strong> &#8377; {order.totalAmount}</Typography>
                </Grid>
              </Grid>

              <Typography variant="h6" gutterBottom>Items</Typography>
              {order.items.map((item, index) => (
                <Grid container key={index} sx={{ borderBottom: '1px solid #ddd', paddingY: 1 }}>
                  <Grid item xs={8}>
                    <Typography variant="body2">{item.name}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">Qty: {item.qty}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">&#8377; {item.price}</Typography>
                  </Grid>
                </Grid>
              ))}
                <Divider sx={{ my: 2 }} />

                <Typography variant="h6">Order Tracking</Typography>
                <Timeline>
                {tracking.map((track, index) => (
                    <TimelineItem key={index}>
                    <TimelineSeparator>
                        <TimelineDot color={track.status === 'Delivered' ? 'success' : 'primary'}>
                        {track.status === 'Delivered' ? <CheckCircle /> : <LocalShipping />}
                        </TimelineDot>
                        {index < tracking.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="body2"><strong>{track.status}</strong></Typography>
                        <Typography variant="caption">{track.date} - {track.time}</Typography>
                    </TimelineContent>
                    </TimelineItem>
                ))}
                </Timeline>
                {/* <Invoice order={invoice_orders[index]} /> */}
                {order.cancellable && (
                <Alert severity="warning" sx={{ mt: 3 }}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Typography><strong>This order is eligible for cancellation.</strong></Typography>
                    <Button
                      variant="contained"
                      color="error"
                      startIcon={<Cancel />}
                    >
                      Cancel Order
                    </Button>
                  </Grid>
                </Alert>
              )}
            </CardContent>
          </Collapse>
          <CardActions sx={{ justifyContent: 'space-between', padding: '16px' }}>
            <Typography variant="body2"><strong>Order Status:</strong> {order.status}</Typography>
            <Typography variant="body2"><strong>Order Total:</strong> &#8377; {order.totalAmount}</Typography>
          </CardActions>
        </Card>
      ))}
    </Box>
  );
}

// Manage Addresses Component
function ManageAddresses() {
  const [open, setOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(1);
  const addresses = [
    { id: 1, type: 'Home', address: '123 Main St, New York, NY, 10001' },
    { id: 2, type: 'Office', address: '456 Office Park, Los Angeles, CA, 90001' },
    { id: 3, type: 'Other', address: '789 Elm St, Chicago, IL, 60601' },
  ];
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box>
      {/* Address List */}
      {addresses.map((item) => (
        <Box
          key={item.id}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={2}
          border={1}
          borderColor="grey.300"
          borderRadius={2}
          mb={2}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedAddress === item.id}
                onChange={() => setSelectedAddress(item.id)}
                color="primary"
              />
            }
            label={
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {item.type}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {item.address}
                </Typography>
              </Box>
            }
          />
          <IconButton color="primary" onClick={handleOpen}>
            <Edit />
          </IconButton>
        </Box>
      ))}

      {/* Add New Address Button */}
      <Button variant="outlined" color="primary" fullWidth onClick={handleOpen} size="large">
        Add New Address
      </Button>

      {/* New Address Dialog (Popup) */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Add New Address</DialogTitle>
        <DialogContent>
          <Box component="form" mt={1} noValidate autoComplete="off">
            <Box display="flex" gap={2} mb={2}>
              <TextField label="First Name" variant="outlined" fullWidth required />
              <TextField label="Last Name" variant="outlined" fullWidth required />
            </Box>
            <TextField label="Phone Number" variant="outlined" fullWidth required sx={{ mb: 2 }}/>
            <Box display="flex" gap={2} mb={2}>
            <TextField label="Zip Code" variant="outlined" fullWidth required />
            </Box>
            <TextField label="Street Address" variant="outlined" fullWidth required sx={{ mb: 2 }} />
            <Box display="flex" gap={2} mb={2}>
              <TextField
                label="State"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                fullWidth
                value="NY"
                required
              >
                <option value="">Select</option>
                <option value="NY">NY</option>
                <option value="CA">CA</option>
                {/* Add more states as needed */}
              </TextField>
              <TextField label="City" variant="outlined" fullWidth required />
            </Box>
            <Box display="flex" gap={2} mb={2}>
              <TextField label="Type Of Address" variant="outlined" fullWidth />
            </Box>
            <Box display="flex" gap={2} mb={2}>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Make this as my default address" />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleClose} variant="contained" color="primary">
            Save Address
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

// Reward Points Component
function Reward() {
    return (
      <Container maxWidth="md" sx={{ py: 5 }}>
      {/* Reward Balance */}
      <Card sx={{ my: 3 }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3} display="flex" justifyContent="center">
              <Avatar sx={{ bgcolor: '#FFD700', width: 64, height: 64 }}>
                <Star fontSize="large" />
              </Avatar>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h5">Total Points</Typography>
              <Typography variant="h3" color="primary">3200</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Recent Activities */}
      <Box sx={{ my: 4 }}>
        <Typography variant="h6" gutterBottom>
          Recent Activities
        </Typography>
        <Grid container spacing={2}>
          {[
            { id: 1, title: 'Earned for Purchase', date: 'Nov 1, 2024', points: 200 },
            { id: 2, title: 'Bonus Points', date: 'Oct 20, 2024', points: 500 },
            { id: 3, title: 'Referral Points', date: 'Oct 10, 2024', points: 300 },
          ].map((activity) => (
            <Grid item xs={12} sm={4} key={activity.id}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <History color="action" />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="subtitle1">{activity.title}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {activity.date}
                      </Typography>
                      <Typography variant="subtitle2" color="primary">
                        +{activity.points} pts
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Redeem Points */}
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Redeem Points
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Exchange your points for exclusive discounts and vouchers!
        </Typography>
        <Button
          variant="contained"
          startIcon={<Redeem />}
          sx={{ mt: 2 }}
          size="large"
        >
          Redeem Now
        </Button>
      </Box>
    </Container>
    );
  }

// Manage Addresses Component
function Refer() {
  const [copied, setCopied] = useState(false);
  const referralLink = "https://thirtyseven.in/referral?code=YOURCODE";

  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copy status after 2 seconds
  };
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Refer a Friend and Earn Points!',
          text: 'Join our platform using my referral link and earn rewards!',
          url: referralLink,
        });
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing', error);
      }
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };
  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      {/* Referral Card */}
      <Card sx={{ textAlign: 'center', p: 3 }}>
        <CardContent>
          <Avatar sx={{ bgcolor: '#FFD700', width: 64, height: 64, mx: 'auto', mb: 2 }}>
            <PersonAdd fontSize="large" />
          </Avatar>
          <Typography variant="h5">Earn 50 Points</Typography>
          <Typography variant="body1" color="textSecondary">
            Share your unique link with friends, and earn points when they join and make a purchase!
          </Typography>

          {/* Referral Link */}
          <Box sx={{ my: 3 }}>
            <TextField
              fullWidth
              variant="outlined"
              value={referralLink}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCopy}
                    startIcon={<FileCopy />}
                  >
                    {copied ? "Copied!" : "Copy"}
                  </Button>
                ),
              }}
            />
          </Box>

          {/* Social Sharing Buttons */}
          <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleShare}
                startIcon={<Share />}
                sx={{ mt: 2 }}
              >
                Share with Friends
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Footer */}
      <Typography variant="body2" color="textSecondary" align="center">
        Terms and conditions apply. Points are awarded only when your friend successfully joins or makes a purchase.
      </Typography>
    </Container>
  );
}

export default ProfilePage;
