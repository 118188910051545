import React, {useEffect} from 'react';
import { Box, Grid, Typography, TextField, Button, Card, CardContent, IconButton } from '@mui/material';
import { Phone, Email, AttachFile } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

const ContactUs = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  return (
    <Box sx={{ flexGrow: 1, p: 3, minHeight: '100vh' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Contact Us
      </Typography>
      
      <Grid container spacing={4} justifyContent="center">
        
        {/* Contact Form Section */}
        <Grid item xs={12} md={6}>
          <Card elevation={3} sx={{ p: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Send Us a Message
              </Typography>
              <form>
                <TextField 
                  label="Name" 
                  variant="outlined" 
                  fullWidth 
                  required 
                  margin="normal" 
                />
                <TextField 
                  label="Email" 
                  variant="outlined" 
                  fullWidth 
                  required 
                  type="email" 
                  margin="normal" 
                />
                <TextField 
                  label="Message" 
                  variant="outlined" 
                  fullWidth 
                  required 
                  multiline 
                  rows={4} 
                  margin="normal" 
                />
                
                {/* File Upload */}
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<AttachFile />}
                  sx={{ mt: 2 }}
                >
                  Attach a file
                  <input type="file" hidden />
                </Button>
                
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth 
                  sx={{ mt: 3 }}
                  type="submit"
                >
                  Submit
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Contact Info Section */}
        <Grid item xs={12} md={4}>
          <Card elevation={3} sx={{ p: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Contact Information
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <IconButton color="primary">
                  <Phone />
                </IconButton>
                <Typography variant="body1" sx={{ ml: 1 }}>
                  +1 (123) 456-7890
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <IconButton color="primary">
                  <Email />
                </IconButton>
                <Typography variant="body1" sx={{ ml: 1 }}>
                  contact@example.com
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;
