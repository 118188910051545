// Header.js
import React, { useState } from 'react';
import "./style.css";
import Sidebar from './Sidebar';


function Header() {
    const [topLeftContent, setTopLeftContent] = useState("It's Offers Time! ")
    const [topRightContent, setTopRightContent] = useState(" Save an extra 10% on your first purchase.")
    
    const [showSideNav, setShowSideNav] = useState(false);
    const toggleSideNav = () => setShowSideNav(!showSideNav);
    const closeSideNav = () => setShowSideNav(false);

    return (
        <>
        <div className="top-bar">
          <marquee className="left-content right-content"><span style={{fontSize:"large"}}>{topLeftContent}</span>{topRightContent}</marquee>
        </div>
        <Sidebar />
      </>
    );
  }

export default Header;
