import React, { useState, useEffect } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import WishlistItem from "../../components/WishlistItem";

import home11 from '../../img/home11.jpg';
import home12 from '../../img/home12.jpg';
import { useLocation } from 'react-router-dom';
import Loader from "../../components/Loader"; // Import the Loader component

const Wishlist = () => {
  const { pathname } = useLocation();
  const [wishlistItems, setWishlistItems] = useState([
    {
      id: 1,
      name: "Men's Classic Shirt",
      color: "Blue",
      articleNo: "5563699",
      price: "$39.99",
      image: home11,
    },
    {
      id: 2,
      name: "Casual Cotton Shirt",
      color: "White",
      articleNo: "5563700",
      price: "$29.99",
      image: home12,
    },
  ]);

  const handleRemove = (id) => {
    setWishlistItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  const handleAddToBag = (item) => {
    // Logic to add a single item to the shopping bag
    console.log(`Added ${item.name} to shopping bag.`);
  };

  const handleAddAllToBag = () => {
    // Logic to add all items to the shopping bag
    console.log("Added all items to shopping bag.");
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000); // Adjust the timeout duration as needed
  }, []);

  return (
    <Container maxWidth="md">
      {loading ? <Loader /> : ""}
      <Typography variant="h4" gutterBottom>
        Your Wishlist
      </Typography>
      {wishlistItems.length === 0 ? (
        <Typography variant="body1" color="text.secondary">
          Your wishlist is empty.
        </Typography>
      ) : (
        <>
          {wishlistItems.map((item) => (
            <WishlistItem key={item.id} item={item} onRemove={handleRemove} onAddToBag={handleAddToBag} />
          ))}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Typography variant="body1" gutterBottom>
              Do you want to purchase your entire wishlist?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddAllToBag}
              sx={{ mt: 1 }}
            >
              Add all to Shopping Bag
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default Wishlist;
