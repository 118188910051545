import React,{useEffect} from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { TbShoppingBagCheck } from "react-icons/tb";


function OrderConfirmation() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
     
    const ProductList = () => {
        navigate('/ProductList');
    } 
    const OrderDetails = () => {
        navigate('/OrderDetails');
    }
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on route change
    }, [pathname]);
    return (
        <Container className="mt-5">
            {/* Header Section */}
            <Row className="text-center mb-4">
                <Col>
                    <h1 className='d-flex justify-content-center text-success'><TbShoppingBagCheck size={50}/> <span className='d-flex align-items-center'>Order Success</span></h1>
                    <h2>THANKS FOR YOUR ORDER!</h2>
                    <p>Your order <strong>#0068521705</strong> is in the works. We sent an email to <strong>youremail@example.com</strong> with your order <a href="#receipt">receipt</a>.</p>
                </Col>
            </Row>

            {/* Order Details Section */}
            <Row className="mb-4">
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>📅 Estimated Arrival</Card.Title>
                            <Card.Text>
                                May 25 - May 31
                                <br />
                                To ensure prompt delivery, some items may ship separately.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>📍 Shipping To</Card.Title>
                            <Card.Text>
                                Tracking information will be available once your order ships.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>❓ Have a Question?</Card.Title>
                            <Card.Text>
                                <p><strong>1 (888) 232-4535</strong></p>
                                <Button variant="link">EMAIL US</Button>
                                <br />
                                <Button variant="link">Chat With Us</Button>
                                <p>Need to return something? Print or show this page in-store to use as a receipt.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* Footer Section */}
            <Row className="text-center mb-4">
                <Col>
                    <button type="button" onClick={OrderDetails} className='btn btn-outline-info'>View Order Details</button>
                </Col>
                <Col>
                    <button type="button" onClick={ProductList} className='btn btn-outline-success'>Continue Shopping</button>
                </Col>
            </Row>
        </Container>
    );
}

export default OrderConfirmation;
