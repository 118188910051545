import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeForm from './pages/home/HomeForm';
import ProductList from './pages/product/ProductList';
import CartPage from './pages/cart/CartPage';
import Checkout from './pages/checkout/Checkout';
import OrderDetails from './pages/OrderDetails/OrderDetails';
import OrderConfirmation from './pages/orderconfirm/OrderConfirmation';
import ProfilePage from './pages/profile/ProfilePage';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';
import Wishlist from './pages/wishlist/Wishlist';
import ContactUs from './pages/contactus/ContactUs';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <div className="rightSideContent">
          <Routes>
            <Route exact path="/" element={<HomeForm />} />
            <Route exact path="/ProductList" element={<ProductList />} />
            <Route exact path="/ProductList" element={<ProductList />} />
            <Route exact path="/CartPage" element={<CartPage />} />
            <Route exact path="/Checkout" element={<Checkout />} />
            <Route exact path="/OrderConfirmation" element={<OrderConfirmation />} />
            <Route exact path="/OrderDetails" element={<OrderDetails />} />
            <Route exact path="/ProfilePage" element={<ProfilePage />} />
            <Route exact path="/Wishlist" element={<Wishlist />} />
            <Route exact path="/ContactUs" element={<ContactUs />} />
            {/* Define more routes here if needed */}
          </Routes>
          <ScrollToTopButton />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
