import React, {useState} from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import './Footer.css'; // Import CSS for styling
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { BsSend } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const [aboutUsshow, setAboutUsShow] = useState(false);
  const [returnshow, setReturnShow] = useState(false);

  const handleAboutUsClose = () => setAboutUsShow(false);
  const handleAboutUsShow = () => setAboutUsShow(true);

  const handleReturnClose = () => setReturnShow(false);
  const handleReturnShow = () => setReturnShow(true);
  return (
    <>
    <div className="footer-banner"></div>
    <footer className="footer">
      <Container>
        <Row>
          <Col md={4} sm={6} xs={12}>
            <h5>Quick Links</h5>
            <ul className="list-unstyled footer-menu">
              <li onClick={() => navigate('/')}>Home</li>
              <li onClick={() => navigate('/ProductList')}>Products</li>
              <li onClick={() => navigate('/Checkout')}>Cart</li>
              <li onClick={() => navigate('/Wishlist')}>Wishlist</li>
            </ul>
          </Col>
          <Col md={4} sm={6} xs={12}>
            <h5>Support</h5>
            <ul className="list-unstyled footer-menu">
              <li onClick={() => navigate('/ContactUs')}>Contact Us</li>
              <li onClick={handleReturnShow}>Return & Exchange</li>
              <li onClick={handleAboutUsShow}>About Us</li>
            </ul>
          </Col>
          <Col md={4} sm={6} xs={12}>
            <h5>Contact Us</h5>
            <p>Timings: 10 AM - 7 PM (Mon - Sat)</p>
            <p>Phone: +123 456 7890</p>
            <p>Email: info@example.com</p>
          </Col>
          <Col md={4} sm={12} xs={12}>
            <h5 className="social-icons ">Follow Us</h5>
            <div className="social-icons pb-3">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                    <FaFacebookF className="social-icon" />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="social-icon" />
                </a>
                <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                    <FaYoutube className="social-icon" />
                </a>
                <a href="https://x.com" target="_blank" rel="noopener noreferrer">
                    <FaXTwitter className="social-icon" />
                </a>
                </div>
          </Col>
          <Col md={4} sm={12} xs={12}>
          <h5 className="social-icons">Stay Ahead of the Curve</h5>
          <p className="social-icons">Join Our Newsletter for Timely News and Updates</p>
            <div className="newsletter-container">
              <input
                type="email"
                placeholder="Enter your email"
                className="newsletter-input"
              />
              <button className="newsletter-button">
                <BsSend alt="Send" className="send-icon" />
              </button>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="footer-bottom">
        <Container>
          <Row>
            <Col md={12}>
              <p className="text-center">&copy; {new Date().getFullYear()} ThirtySeven. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
    <Modal show={returnshow} onHide={handleReturnClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Return and Exchange Policy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p >
          At Thirtyseven, we want you to be completely satisfied with your purchase. 
          If for any reason you're not happy with an item, we offer a hassle-free return and exchange policy. 
          Here’s everything you need to know:
        </p>
        <h5>Eligibility for Returns and Exchanges</h5>
        <ul className="list-unstyled p-2">
          <li>Returns and exchanges are allowed within 7 days from the date of delivery.</li>
          <li>Items must be in their original condition, with tags attached, unworn, unwashed, and free from any damage or alterations.</li>
          <li>Certain items (such as final sale or clearance items) may not be eligible for return or exchange. Please check the product page for any specific exclusions.</li>
        </ul>
        <h5>How to Initiate a Return or Exchange</h5>
        <ul className="list-unstyled p-2">
          <li>To start a return or exchange, please contact our customer service team at support@thirtyseven.com or call us at (XXX) XXX-XXXX.</li>
          <li>Provide your order number, the item(s) you wish to return or exchange, and the reason for the return.</li>
          <li>Our team will provide you with instructions on how to return the item(s).</li>
        </ul>
        <h5>Refunds and Exchange Processing</h5>
        <ul className="list-unstyled p-2">
          <li><strong>Returns:</strong> Once we receive and inspect your returned item(s), we will process a refund to your original payment method. Refunds typically take 5–7 business days to appear in your account.</li>
          <li><strong>Exchanges:</strong> If you prefer an exchange, please let us know which item or size you would like. We will process and ship the exchange once we receive the original item.</li>
        </ul>
        <h5>Return and Exchange Shipping</h5>
        <ul className="list-unstyled p-2">
          <li>Customers are responsible for return shipping costs unless the item was defective or incorrect due to our error.</li>
          <li>For exchanges, we will cover the cost of shipping the new item back to you.</li>
        </ul>
        <h5>Damaged or Defective Items</h5>
        <p className="p-2">
          If you receive an item that is damaged or defective, please contact us within 48 hours of delivery. 
          We will arrange a replacement at no additional cost.
        </p>
        <p>
          We appreciate your understanding and support for our policy. If you have any questions or concerns, 
          please don’t hesitate to reach out. Our goal is to make your experience with Thirtyseven as smooth and satisfying as possible.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleReturnClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal show={aboutUsshow} onHide={handleAboutUsClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>About Thirtyseven</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Welcome to Thirtyseven</h5>
        <p className='p-2'>
          Thirtyseven was founded with a vision to redefine men’s fashion. Our brand is built around the 
          idea of offering high-quality, thoughtfully designed apparel that caters to the modern man. 
          Each piece in our collection is carefully crafted to deliver both style and comfort, making 
          it easy for our customers to express their individuality with confidence.
        </p>
        
        <h5>Our Story</h5>
        <p className='p-2'>
          What started as a small endeavor has grown into a trusted brand for men who seek style, comfort, 
          and durability. At Thirtyseven, we understand that fashion is more than just clothing—it's a 
          reflection of who you are. That's why our journey began with a mission to create clothing that 
          meets the needs of today’s men while keeping up with timeless trends and high-quality standards.
        </p>
        
        <h5>Our Mission and Values</h5>
        <p className='p-2'>
          We are driven by a mission to empower men to look and feel their best in every situation, 
          whether at work, at a gathering, or during casual outings. Our values are rooted in:
          <ul className='list-unstyled p-2'>
            <li>Quality: We source premium fabrics and materials to ensure every item meets our rigorous 
            standards of durability and comfort.</li>
            <li>Innovation: Fashion is always evolving, and so are we. We blend classic elements with 
            contemporary designs to keep our collection fresh and relevant.</li>
            <li>Integrity: Our customers' trust is our top priority. We are committed to transparency, 
            ethical practices, and outstanding service.</li>
          </ul>
        </p>
        
        <h5>Our Products</h5>
        <p className='p-2'>
          The Thirtyseven collection includes a wide range of clothing essentials for men:
          <ul className='list-unstyled p-2'>
            <li><strong>Casual Wear:</strong> From trendy t-shirts and jeans to hoodies and jackets, our casual 
            wear is designed to give you a cool, relaxed look with a touch of refinement.</li>
            <li><strong>Formal Wear:</strong> Discover sophisticated shirts, blazers, and pants that are 
            perfect for the office or formal occasions.</li>
            <li><strong>Accessories:</strong> Complete your outfit with our carefully curated selection of 
            accessories, including belts, ties, and more.</li>
          </ul>
        </p>

        <h5>Why Thirtyseven?</h5>
        <p className='p-2'>
          Choosing Thirtyseven means opting for more than just clothing. Our brand is dedicated to helping you 
          build a wardrobe that reflects your personality and enhances your lifestyle. With our focus on 
          quality, style, and customer satisfaction, we strive to deliver a shopping experience like no other.
        </p>

        <p>
          Thank you for choosing Thirtyseven. We're excited to have you as part of our journey, and we look 
          forward to helping you dress your best. Join us as we continue to elevate men's fashion and make an 
          impact in the world of style.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleAboutUsClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default Footer;
